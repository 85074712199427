<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />

              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu" class="font-weight-bold h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button squared block type="submit" :disabled="!firmaKNo" :variant="form.k_no == null ? 'primary' : 'warning'">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">{{ form.k_no == null ? 'Ekle' : 'Güncelle' }}</span>
          </b-button>
          <b-button squared block type="submit" v-if="form.k_no" @click="resetForm">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">iptal</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, toRefs, watch, computed, toRef } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    const { updateData } = toRefs(props);
    expo.form = ref({
      k_no: null,
      k_no: null,
      baslik: null,
      statu: true,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        k_no: null,
        baslik: null,
        statu: true,
      };
      context.emit('showLoading', false);
      context.refs.baslik.focus();
    };
    expo.onSubmit = () => {
      context.emit('showLoading', true);
      const basrUrl = expo.form.value.k_no == null ? 'tagEkle' : 'tagGuncelle';

      store
        .dispatch(basrUrl, expo.form.value)
        .then((res) => {
          if (res.data.success === true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            context.emit('refresh', res.data.data);
            expo.resetForm();
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text: err.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    watch(updateData, (val) => {
      expo.form.value = { ...val };
    });

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
